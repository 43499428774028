import React from "react";

import { Grid } from "@material-ui/core";
import RouteForm from "../../RouteForm/RouteForm";
import Routes from "../../Routes/Routes.jsx";
import Prices from "../../Prices/Prices";
import SectionContainer from "../../SectionContainer/SectionContainer.jsx";

import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import NewsSection from "../../News/NewsSection/NewsSection";

import { Typography } from "@material-ui/core";

import styles from "./MainView.module.scss";
import InfoSection from "../../InfoSection/InfoSection";
import CountriesImage from "../../../../media/3_countries_countour_white.webp";
import Bus from "../../../../media/bus_with_passengers_new.webp";


class MainView extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        };

    }

    render() {

        return (
            <div className={styles["main-container"]}>

                <div className={styles["top-background"]}></div>

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center">
                    
                    <Grid container justifyContent="space-around" 
                        direction={this.props.mediaSize !== 'xs' && this.props.mediaSize !== 'md' ? 'row' : 'column-reverse'}
                        className={styles["top-container"]}>
                        <Grid item xs={12} md={4} className={styles['route-form']}>

                            <RouteForm 
                                formControlsfullWidth={true}
                                className={styles['section']}
                                setNewRouteFormData={this.props.setNewRouteFormData}
                                historyPush={this.props.historyPush}
                                routeFormData={this.props.routeFormData}
                                mediaSize={this.props.mediaSize}
                                activeStep="basic_form"
                                activeStepIndex={-1}
                            />
                        </Grid>
                        <Grid item md={6} 
                            style={{
                                display: "flex", 
                                position: "relative", 
                                flexDirection: "column", 
                                justifyContent: "space-between", 
                                zIndex: 100, 
                                color: "white"
                            }} 
                            className={styles['heading']}>
                            
                            <div className={styles["poland-pin"]}>
                                <div style={{display: "flex", flexDirection: "column", gap: "0.1rem", alignItems: "center"}}>

                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" height={"1rem"}>
                                            <path fill="red" fill-rule="evenodd" d="m7.539 14.841.003.003.002.002a.755.755 0 0 0 .912 0l.002-.002.003-.003.012-.009a5.57 5.57 0 0 0 .19-.153 15.588 15.588 0 0 0 2.046-2.082c1.101-1.362 2.291-3.342 2.291-5.597A5 5 0 0 0 3 7c0 2.255 1.19 4.235 2.292 5.597a15.591 15.591 0 0 0 2.046 2.082 8.916 8.916 0 0 0 .189.153l.012.01ZM8 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" clip-rule="evenodd" />
                                        </svg>
                                    </div>

                                    <div>
                                        Polska
                                    </div>

                                </div>
                            </div>
                            <div className={styles["germany-pin"]}>
                                <div style={{display: "flex", flexDirection: "column", gap: "0.1rem", alignItems: "center"}}>

                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" height={"1rem"}>
                                            <path fill="red" fill-rule="evenodd" d="m7.539 14.841.003.003.002.002a.755.755 0 0 0 .912 0l.002-.002.003-.003.012-.009a5.57 5.57 0 0 0 .19-.153 15.588 15.588 0 0 0 2.046-2.082c1.101-1.362 2.291-3.342 2.291-5.597A5 5 0 0 0 3 7c0 2.255 1.19 4.235 2.292 5.597a15.591 15.591 0 0 0 2.046 2.082 8.916 8.916 0 0 0 .189.153l.012.01ZM8 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" clip-rule="evenodd" />
                                        </svg>
                                    </div>

                                    <div>
                                        Niemcy
                                    </div>

                                </div>
                            </div>
                            <div className={styles["holland-pin"]}>
                                <div style={{display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center"}}>

                                    <div>
                                        Holandia
                                    </div>

                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" height={"1rem"}>
                                            <path fill="red" fill-rule="evenodd" d="m7.539 14.841.003.003.002.002a.755.755 0 0 0 .912 0l.002-.002.003-.003.012-.009a5.57 5.57 0 0 0 .19-.153 15.588 15.588 0 0 0 2.046-2.082c1.101-1.362 2.291-3.342 2.291-5.597A5 5 0 0 0 3 7c0 2.255 1.19 4.235 2.292 5.597a15.591 15.591 0 0 0 2.046 2.082 8.916 8.916 0 0 0 .189.153l.012.01ZM8 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" clip-rule="evenodd" />
                                        </svg>
                                    </div>

                                </div>
                            </div>
                            <div className={styles["bus"]}>
                                <div className={styles["bus-img2"]}>
                                    <img src={Bus} style={{height: "5rem"}} className={styles["bus-img"]}/>
                                </div>
                            </div>
                            <div>
                                <img src={CountriesImage} className={styles["countries-img"]}/>
                            </div>
                        </Grid>
                    </Grid>

                    <div className={styles['top-text-container']}>
                        <div className={styles['top-text']}>
                            
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>

                                <Typography variant="h4" color="inherit">
                                    Przejazdy z adresu pod adres - busy do Polski, Niemiec i Holandii<br/>
                                </Typography>

                            </div>
                            
                        </div>
                    </div>

                    <Grid 
                        container 
                        justifyContent="space-around" 
                        direction='row-reverse'
                        style={{marginBottom: "2em", marginTop: "10rem"}}>

                        <Grid item xs={12} style={this.props.mediaSize !== "xs" ? {marginBottom: "4em"} : {}}>
                            <NewsSection/>
                        </Grid>

                        <Grid item xs={12} md={12}>

                            <div className={styles["sections-container"]}>

                                <Grid container justifyContent="space-around">

                                    <Grid item xs={12} md={12}>
                                        <Routes
                                            id="routes"
                                            className={styles.section}
                                            expanded={this.props.route === "routes" || this.props.mediaSize !== "xs"}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <div className={styles["prices-component"]}>
                                            <Prices
                                                id="prices"
                                                className={styles.section}
                                                expanded={this.props.route === "prices" || this.props.mediaSize !== "xs"}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                            </div>

                            <div className={styles.section} style={{marginTop: "1em", display: this.props.mediaSize !== "xs" ? "none" : "block"}}>
                                <SectionContainer
                                    expandable={false}
                                    title="Kontakt"
                                    icon={<PersonPinOutlinedIcon
                                        color="primary"
                                        style={{display: "block"}}
                                        fontSize="inherit"
                                    />}
                                    routeConfig={{name: "routes", to: "/kontakt"}}>
                                </SectionContainer>
                            </div>

                            <div className={styles.section} style={{marginTop: "1em"}}>
                                    
                                <InfoSection/>

                            </div>

                        </Grid>

                    </Grid>

                    {/* <TeasersSection/> */}
                </Grid>
            </div>
        );
    
    }

}

export default MainView;