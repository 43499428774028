// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html{font-size:16px}body{font-family:\"Roboto\",\"Helvetica\",\"Arial\",sans-serif;min-height:100vh}.\\+PwHKFHOBab1PqNKjbOvQg\\=\\={padding-top:1.5rem}*{box-sizing:border-box}p{margin:0}", "",{"version":3,"sources":["webpack://./src/js/components/App/App.module.scss","webpack://./src/css/_variables.module.scss"],"names":[],"mappings":"AAEA,KACI,cAAA,CAGJ,KACI,mDCUgB,CDThB,gBAAA,CAGJ,6BACI,kBAAA,CAGJ,EACI,qBAAA,CAGJ,EACI,QAAA","sourcesContent":["@import \"../../../css/variables.module\";\n\nhtml {\n    font-size: 16px;\n}\n\nbody {\n    font-family: $basic-font-family;\n    min-height: 100vh;\n}\n\n.container {\n    padding-top: 1.5rem;\n}\n\n* {\n    box-sizing: border-box;\n}\n\np {\n    margin: 0;\n}","$primary-color: #033572;\n$color-2: #E56B70;\n$color-3: #B0228C;\n$color-4: #EA3788;\n$color-5: #F391A0;\n$subtle-text-color: #616161;\n\n$first-section-margin: 1.5em;\n$basic-border-radius: 20px;\n$basic-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);\n\n//footer\n$footer-section-title-color: $primary-color;\n$footer-subsection-color: black;\n\n$rental-bg-color: #E9F9FF;\n\n$basic-font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n\n$success_color: green;\n$error_color: red;\n$link-color: #0AC2FF;\n$light-grey: #e7e7e7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "+PwHKFHOBab1PqNKjbOvQg=="
};
export default ___CSS_LOADER_EXPORT___;
