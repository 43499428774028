import React from "react";

import { Toolbar, Container, Hidden } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { MenuOutlined } from "@material-ui/icons";
import { PhoneOutlined } from "@material-ui/icons";
import { MailOutlined } from "@material-ui/icons";
import { Facebook } from '@material-ui/icons';
import { Instagram } from '@material-ui/icons';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

import Logo from "../../../media/logo_nowy_kolor.png";

import styles from "./Header.module.scss";
import { CustomAppBarContainer } from "./CustomAppBar/CustomAppBar.jsx";

import { Link } from "react-router-dom";

import MenuPC from "./MenuPC/MenuPC.jsx";

function HideOnScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        threshold: 50
    });
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
}


class Header extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        };

    }

    render() {

        return (
            <HideOnScroll {...this.props}>
                <CustomAppBarContainer>
                    <Container>
                        <Toolbar variant="dense" disableGutters={true}>
                            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                <Hidden smDown={true}>
                                    <div className={styles["header-container-pc"]}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <PhoneOutlined/>&nbsp;+48 774 311 370
                                        </div>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <MailOutlined/>&nbsp;rezerwacje@translison.pl
                                        </div>
                                        <IconButton color="primary" aria-label="menu" href="https://www.facebook.com/LisonTransport/">
                                            <Facebook/>
                                        </IconButton>
                                        <IconButton color="primary" aria-label="menu" href="https://www.facebook.com/LisonTransport/">
                                            <Instagram/>
                                        </IconButton>
                                    </div>
                                </Hidden>
                                <div className={styles["header-container"]}>
                                    
                                    <Hidden mdUp={true}>
                                        <IconButton color="primary" aria-label="menu" onClick={this.props.openMenu}>
                                            <MenuOutlined/>
                                        </IconButton>
                                    </Hidden>
                                    
                                    <div className={styles["logo-container"]}>
                                        <Link to="/">
                                            <img src={Logo} alt="Logo" className={styles.logo} />
                                        </Link>
                                    </div>

                                    <Hidden smDown={true}>
                                        <MenuPC/>
                                    </Hidden>

                                    <Hidden smUp={true}>

                                        <IconButton color="primary" aria-label="menu" href="tel:+48774311370">
                                            <PhoneOutlined/>
                                        </IconButton>
                                        <IconButton color="primary" aria-label="menu" href="mailto:rezerwacje@translison.pl">
                                            <MailOutlined/>
                                        </IconButton>
                                        <IconButton color="primary" aria-label="menu" href="https://www.facebook.com/LisonTransport/">
                                            <Facebook/>
                                        </IconButton>
                                        <IconButton color="primary" aria-label="menu" href="https://www.facebook.com/LisonTransport/">
                                            <Instagram/>
                                        </IconButton>

                                    </Hidden>

                                </div>
                            </div>
                        </Toolbar>
                    </Container>
                </CustomAppBarContainer>
            </HideOnScroll>
        );
    }

}

export default Header;