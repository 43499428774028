import React, { Component } from "react";

import styles from "./InfoSection.module.scss";
import { Typography } from "@material-ui/core";

class InfoSection extends Component {

    render() {

        return (
            <div className={styles["main-container"]}>
                <Typography variant="h4">Translison Przewozy Osobowe - busy za granicę z adresu pod adres</Typography>
                <Typography variant="body1" style={{marginTop: "1em"}}>
                    Translison Przewozy Osobowe to firma oferująca przewóz osób do Niemiec i Holandii. Nasz zespół tworzą wykwalifikowani i doświadczeni zawodowi kierowcy, których cechuje wysoka kultura osobista. Firma dysponuje nowoczesną, klimatyzowaną flotą pojazdów typu bus marki Ford Transit. Nasze przewozy busem odbywają się w komfortowych i bezpiecznych warunkach, gdyż samochody charakteryzują się wysokim standardem, w tym:
                
                </Typography>

                <div>
                    <ul>
                        <li>dużą przestrzenią na nogi</li>
                        <li>regulowanymi fotelami</li>
                        <li>podłokietnikami dla każdego pasażera</li>
                        <li>wytrzymałością, funkcjonalnością i niezawodnością</li>
                    </ul>
                </div>
                
                <Typography variant="body1" style={{marginTop: "1em"}}>
                    Przewozy osób busem odbywają się systematycznie, siedem dni w tygodniu. Zawozimy każdego pasażera pod wskazany adres podany podczas rezerwacji. Systematycznie modernizujemy flotę oraz regularnie serwisujemy nasze auta. Pozwala nam to na bezawaryjne pokonywanie wyznaczonych tras i świadczenie usług przewozu osób za granicę z dbałością o bezpieczeństwo i wygodę pasażerów.
                </Typography>

                <Typography variant="h4" style={{marginTop: "1em"}}>Międzynarodowy przewóz osób do Niemiec i Holandii busem - dlaczego warto skorzystać?</Typography>
                <Typography variant="body1" style={{marginTop: "1em"}}>
                    Usługi przewozu osób za granicę odbywają się zgodnie z najwyższymi standardami - naszym celem jest zaspokojenie wszelkich oczekiwań pasażerów. Przewozy z adresu pod adres niosą ze sobą mnóstwo korzyści:

                    unikasz przesiadek i martwienia się o transport za granicą - zabieramy Cię spod Twojego domu i dowozimy pod wskazany przez Ciebie adres;
                    planujesz podróż w dogodnym dla siebie czasie - nasza firma świadczy usługi przewozu osób codziennie, przez całą dobę;
                    podróżujesz szybko i komfortowo - nasza flota wyposażona jest we wszelkie dogodności, dzięki czemu Twoja podróż przebiegnie wygodnie i bez zakłóceń; każdy bus jest regularnie serwisowany, w związku z tym możesz być pewien, że podróżujesz bezpiecznie;
                    oszczędzasz czas i pieniądze - oferowany przez nas przewóz osób do Niemiec czy Holandii to także atrakcyjna cena. Wybierając transport osób busem w dogodnym dla siebie czasie możesz również zoptymalizować koszty związane z pobytem na miejscu.
                </Typography>

                <Typography variant="h4" style={{marginTop: "1em"}}>Busy za granicę - bezpieczny przewóz osób</Typography>
                <Typography variant="body1" style={{marginTop: "1em"}}>
                    Szukasz pewnego, bezpiecznego i komfortowego transportu? Skorzystaj z Translison Przewozy Osobowe, firmy specjalizującej się w codziennych przejazdach busem z adresu na adres. Oferujemy przewóz osób w wybrane destynacje:
                </Typography>

                <div>
                    <ul>
                        <li>busy do Niemiec</li>
                        <li>busy do Holandii</li>
                        <li>busy do Polski</li>
                    </ul>
                </div>

                <Typography variant="h4" style={{marginTop: "1em"}}>Na co możesz liczyć korzystając z usług transportu osób busem naszego przewoźnika za granicę?</Typography>
                <Typography variant="body1" style={{marginTop: "1em"}}>
                
                    Nasz zespół tworzą wykwalifikowani i doświadczeni zawodowi kierowcy;
                    dysponujemy nowoczesną, klimatyzowaną flotą pojazdów, cechujących się wytrzymałością, funkcjonalnością i niezawodnością;
                    systematycznie modernizujemy i serwisujemy nasze auta, co pozwala nam na bezawaryjne pokonywanie wyznaczonych tras.
                    Jako przewoźnik busem, usługi świadczymy codziennie, a każdego pasażera zawozimy pod wskazany adres podany podczas rezerwacji. Wieloletnie doświadczenie jako firmy przewozowej osób za granicę pozwoliło nam dostosować flotę pojazdów najbardziej odpowiadającą potrzebom naszych klientów - dla nas najważniejsze jest Twoje bezpieczeństwo i wygoda!

                </Typography>
            </div>
        );

    }

}

export default InfoSection;