import React from "react";

import styles from "./Footer.module.scss";
import FooterSection from "./FooterSection/FooterSection.jsx";
import FooterSubsection from "./FooterSubsection/FooterSubsection.jsx";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import PhoneEnabledOutlinedIcon from '@material-ui/icons/PhoneEnabledOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';

import { Grid, Container, Link as LinkMaterial, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";


class Footer extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        };

    }

    render() {

        let oLocalizationIcon = <LocationOnOutlinedIcon
            color="primary"
        />;
        let oContactIcon = <MailOutlinedIcon
            color="primary"
        />;
        let oContact2Icon = <PhoneEnabledOutlinedIcon
            color="primary"
        />;
        let oMoneyIcon = <AttachMoneyOutlinedIcon
            color="primary"
        />;
        let oAssignmentIcon = <AssignmentOutlinedIcon
            color="primary"
        />;
        

        return (
            <div className={styles["main-container"]}>
                <div className={styles["questions-container"]}>
                    <Typography variant="h5">
                        Masz Pytania?
                    </Typography>
                    <Typography variant="h5">
                        Zadzwoń lub sprawdź
                    </Typography>
                    <Button color="inherit" aria-label="menu" variant="outlined" href="/maszpytania">
                        Najczęściej zadawane Pytania
                    </Button>
                        
                </div>
                <div className={styles["footer-container"]}>
                    <Container>
                        <Grid container justifyContent="space-around">
                            <Grid item xs={12} sm={5} md={3}>
                                <FooterSection
                                    title="LOKALIZACJA">
                                    <FooterSubsection icon={oLocalizationIcon}>
                                        Sowin 104<br></br>
                                        48-316 Łambinowice<br></br>
                                        Polska<br/><br/>
                                        <span>
                                            <Link to="/regulamin" style={{textDecoration: "none"}}><LinkMaterial component="span">Regulamin</LinkMaterial></Link><br/>
                                            <Link to="/polityka_prywatnosci" style={{textDecoration: "none"}}><LinkMaterial component="span">Polityka Prywatności</LinkMaterial></Link>
                                        </span>
                                    </FooterSubsection>
                                </FooterSection>
                            </Grid>
                            <Grid item xs={12} sm={5} md={3}>
                                <FooterSection
                                    title="KONTAKT">
                                    <FooterSubsection icon={oContactIcon}>
                                        rezerwacje@translison.pl
                                    </FooterSubsection>
                                    <FooterSubsection icon={oContact2Icon}>
                                        +48 77 431 13 70<br></br>
                                        +48 692 400 449
                                    </FooterSubsection>
                                </FooterSection>
                            </Grid>
                            <Grid item xs={12} sm={5} md={3}>
                                <FooterSection
                                    title="KONTA BANKOWE">
                                    <FooterSubsection icon={oMoneyIcon}>
                                        Numer konta PLN:<br></br>
                                        52 1020 3668 0000 5902 0619 3595<br></br><br></br>
                                        Numer konta EUR:<br></br>
                                        60 1020 3668 0000 5302 0594 1739
                                    </FooterSubsection>
                                </FooterSection>
                            </Grid>
                            <Grid item xs={12} sm={5} md={3}>
                                <FooterSection
                                    title="DANE DO FAKTURY">
                                    <FooterSubsection icon={oAssignmentIcon}>
                                        Transport Osobowy<br></br>
                                        Norbert Lisoń<br></br>
                                        Sowin 107,<br></br>
                                        48-316 Łambinowice<br></br>
                                        NIP: 753-156-50-00
                                    </FooterSubsection>
                                </FooterSection>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        );
    
    }

}

export default Footer;