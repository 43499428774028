// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lfcmwOjQQzrTBA0lNianFA\\=\\={color:#000}", "",{"version":3,"sources":["webpack://./src/js/components/InfoSection/InfoSection.module.scss"],"names":[],"mappings":"AAAA,4BACI,UAAA","sourcesContent":[".main-container {\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-container": "lfcmwOjQQzrTBA0lNianFA=="
};
export default ___CSS_LOADER_EXPORT___;
