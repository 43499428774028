// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t5u6s9s0VAbbXWq7auMWSw\\=\\={width:100%}.XsYlv77cUPX7iJCsJQEyDA\\=\\={display:flex;justify-content:center;margin-top:1.5em}.y12y4TzXh9HTMMYJGXBaFA\\=\\={border-bottom:solid;border-width:1px;border-color:#033572}.Swm35l79uCtO2zXUQtW8rg\\=\\={display:flex;flex-direction:column;width:100%}.FSss2K1OLrSCdH9vKrB0jg\\=\\={padding:1em}", "",{"version":3,"sources":["webpack://./src/js/components/Prices/Prices.module.scss","webpack://./src/css/_variables.module.scss"],"names":[],"mappings":"AAEA,4BACI,UAAA,CAGJ,4BACI,YAAA,CACA,sBAAA,CACA,gBAAA,CAGJ,4BACI,mBAAA,CACA,gBAAA,CACA,oBCfY,CDkBhB,4BACI,YAAA,CACA,qBAAA,CACA,UAAA,CAGJ,4BACI,WAAA","sourcesContent":["@import \"../../../css/variables.module\";\n\n.main-container {\n    width: 100%;\n}\n\n.button-container {\n    display: flex;\n    justify-content: center;\n    margin-top: 1.5em;\n}\n\n.price-section{\n    border-bottom: solid;\n    border-width: 1px;\n    border-color: $primary-color\n}\n\n.price-group-container {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.price-group-additional-info {\n    padding: 1em;\n}","$primary-color: #033572;\n$color-2: #E56B70;\n$color-3: #B0228C;\n$color-4: #EA3788;\n$color-5: #F391A0;\n$subtle-text-color: #616161;\n\n$first-section-margin: 1.5em;\n$basic-border-radius: 20px;\n$basic-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);\n\n//footer\n$footer-section-title-color: $primary-color;\n$footer-subsection-color: black;\n\n$rental-bg-color: #E9F9FF;\n\n$basic-font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n\n$success_color: green;\n$error_color: red;\n$link-color: #0AC2FF;\n$light-grey: #e7e7e7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-container": "t5u6s9s0VAbbXWq7auMWSw==",
	"button-container": "XsYlv77cUPX7iJCsJQEyDA==",
	"price-section": "y12y4TzXh9HTMMYJGXBaFA==",
	"price-group-container": "Swm35l79uCtO2zXUQtW8rg==",
	"price-group-additional-info": "FSss2K1OLrSCdH9vKrB0jg=="
};
export default ___CSS_LOADER_EXPORT___;
