import React from "react";
import styles from "./NewsSection.module.scss";
import { Box, Grid, Button } from "@material-ui/core";
import NewsBase from "../NewsBase/NewsBase.jsx";
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import Request from "../../../utils/Request";
function MatchCheckWrapper() {
    const match = useRouteMatch('/aktualnosci');
    return (React.createElement(NewsSection, { match: match }));
}
const pageSizeSection = 3;
const pageSizeSeparatePage = 10;
class NewsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newsdata: []
        };
        this.loadNews = this.loadNews.bind(this);
        this.loadNews();
    }
    loadNews() {
        if (process.env.NODE_ENV === "development") {
            const newsData = [{
                    title: "Busy do Niemiec",
                    date_from: new Date().toISOString(),
                    text: "W trosce o Państwa bezpieczeństwo regularnie wymieniamy naszą flotę samochodową, dzięki czemu możemy zapewnić najbardziej niezawodne pojazdy dla naszych pasażerów.",
                    image_path: "http://localhost:8081/media/news-example-image.jpg",
                    id: "1"
                }];
            setTimeout(() => {
                this.setState({
                    newsdata: newsData
                });
            }, 0);
            return;
        }
        const now = new Date();
        const nowISO = now.toISOString().substring(0, 10);
        const query = '$filter=' + encodeURIComponent(`date_from <= '${nowISO}' AND (date_to >= '${nowISO}' OR date_to is null)`);
        new Request().get(`${BACKEND_SERVICE}/News?${query}`).then((result) => {
            const newsData = JSON.parse(result).data;
            this.setState({
                newsdata: newsData
            });
        });
    }
    renderNews() {
        let length;
        const objects = [];
        const mainSite = !this.props.match;
        if (!mainSite) {
            length = this.state.newsdata.length < pageSizeSeparatePage ? this.state.newsdata.length : pageSizeSeparatePage;
        }
        else {
            length = this.state.newsdata.length < pageSizeSection ? this.state.newsdata.length : pageSizeSection;
        }
        for (let i = 0; i < length; ++i) {
            objects.push(React.createElement(Grid, { key: this.state.newsdata[i].id, xs: 12, md: 4, item: true, container: true, justifyContent: "center", alignContent: "stretch" },
                React.createElement(NewsBase, { titleVariant: "h5", title: this.state.newsdata[i].title, date: this.state.newsdata[i].date_from, text: this.state.newsdata[i].text, parentKey: this.state.newsdata[i].id, image: this.state.newsdata[i].image_path })));
        }
        return objects;
    }
    render() {
        let moreButton;
        if (this.props.match === null) {
            moreButton = (React.createElement(Link, { to: "/aktualnosci", className: styles["more-button"] },
                React.createElement(Button, { color: "primary", variant: "outlined" }, "WI\u0118CEJ AKTUALNO\u015ACI")));
        }
        return (React.createElement(Box, { className: styles["main-container"] },
            React.createElement(Grid, { container: true, className: styles["news-container"], justifyContent: "flex-start", alignContent: "stretch" }, this.renderNews()),
            React.createElement("div", { className: styles["button-container"] }, moreButton)));
    }
}
export default MatchCheckWrapper;
