// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oSGKAsZTkbokB\\+Hxf4kftw\\=\\={display:flex;flex-direction:column;width:100%;padding-bottom:1em}.I2j2zI89oZrbwrOebsdkng\\=\\={width:100%;background-color:#033572;flex-grow:1;color:#fff;padding:.5rem;display:flex;flex-direction:row;align-items:center;gap:1rem;justify-content:center}.npNdyOpaoHA11ztvcxzHYA\\=\\={width:100%;background-color:#fff;flex-grow:1}", "",{"version":3,"sources":["webpack://./src/js/components/Footer/Footer.module.scss","webpack://./src/css/_variables.module.scss"],"names":[],"mappings":"AAEA,6BACI,YAAA,CACA,qBAAA,CACA,UAAA,CACA,kBAAA,CAGJ,4BACI,UAAA,CACA,wBCXY,CDYZ,WAAA,CACA,UAAA,CACA,aAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,QAAA,CACA,sBAAA,CAGJ,4BACI,UAAA,CACA,qBAAA,CACA,WAAA","sourcesContent":["@import \"../../../css/variables.module\";\n\n.main-container {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    padding-bottom: 1em;\n}\n\n.questions-container {\n    width: 100%;\n    background-color: $primary-color;\n    flex-grow: 1;\n    color: white;\n    padding: 0.5rem;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 1rem;\n    justify-content: center;\n}\n\n.footer-container {\n    width: 100%;\n    background-color: white;\n    flex-grow: 1;\n}","$primary-color: #033572;\n$color-2: #E56B70;\n$color-3: #B0228C;\n$color-4: #EA3788;\n$color-5: #F391A0;\n$subtle-text-color: #616161;\n\n$first-section-margin: 1.5em;\n$basic-border-radius: 20px;\n$basic-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);\n\n//footer\n$footer-section-title-color: $primary-color;\n$footer-subsection-color: black;\n\n$rental-bg-color: #E9F9FF;\n\n$basic-font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n\n$success_color: green;\n$error_color: red;\n$link-color: #0AC2FF;\n$light-grey: #e7e7e7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-container": "oSGKAsZTkbokB+Hxf4kftw==",
	"questions-container": "I2j2zI89oZrbwrOebsdkng==",
	"footer-container": "npNdyOpaoHA11ztvcxzHYA=="
};
export default ___CSS_LOADER_EXPORT___;
