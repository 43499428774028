// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rD9FkFdwisTU-e\\+u9UUCuw\\=\\={display:flex;flex-direction:row;color:#000;margin-top:.5em}._5nZMfHGpcnJVuC3q4EKQ9A\\=\\={flex-grow:1;white-space:normal}.ukGrbOgI122\\+obT1CPtxdw\\=\\={margin-right:.8em}", "",{"version":3,"sources":["webpack://./src/js/components/Footer/FooterSubsection/FooterSubsection.module.scss","webpack://./src/css/_variables.module.scss"],"names":[],"mappings":"AAEA,6BACI,YAAA,CACA,kBAAA,CACA,UCQsB,CDPtB,eAAA,CAGJ,6BACI,WAAA,CACA,kBAAA,CAGJ,6BACI,iBAAA","sourcesContent":["@import \"../../../../css/variables.module\";\n\n.main-container {\n    display: flex;\n    flex-direction: row;\n    color: $footer-subsection-color;\n    margin-top: 0.5em;\n}\n\n.text-container {\n    flex-grow: 1;\n    white-space: normal;\n}\n\n.icon-container {\n    margin-right: 0.8em;\n}","$primary-color: #033572;\n$color-2: #E56B70;\n$color-3: #B0228C;\n$color-4: #EA3788;\n$color-5: #F391A0;\n$subtle-text-color: #616161;\n\n$first-section-margin: 1.5em;\n$basic-border-radius: 20px;\n$basic-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);\n\n//footer\n$footer-section-title-color: $primary-color;\n$footer-subsection-color: black;\n\n$rental-bg-color: #E9F9FF;\n\n$basic-font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n\n$success_color: green;\n$error_color: red;\n$link-color: #0AC2FF;\n$light-grey: #e7e7e7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-container": "rD9FkFdwisTU-e+u9UUCuw==",
	"text-container": "_5nZMfHGpcnJVuC3q4EKQ9A==",
	"icon-container": "ukGrbOgI122+obT1CPtxdw=="
};
export default ___CSS_LOADER_EXPORT___;
