// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Bjr-rdxlkM9vKQ7robkLgw\\=\\={width:100%;padding:.5em 1em .5em 1em}@media only screen and (max-width: 480px){.Bjr-rdxlkM9vKQ7robkLgw\\=\\={border-radius:20px;border-style:solid;border-width:1px;border-color:#033572}}.YV3cD9j9a3dW0S19l2ZrCw\\=\\={display:flex;flex-direction:row;align-items:center;min-height:48px}.EMEiEBi3mxe669klojm0Cg\\=\\={margin-top:1em;padding-bottom:1em}.JctYIp4CZZVdEm74CnyMqA\\=\\={margin-right:.5em;display:inline-block;font-size:35px}.QLOu5DLXJyMn9kpux\\+aHEQ\\=\\={flex-grow:1;display:flex;flex-direction:row;justify-content:flex-end}.\\+ELX6QhCf3KVjSTUEofDPA\\=\\={justify-self:end !important;display:block !important}", "",{"version":3,"sources":["webpack://./src/js/components/SectionContainer/SectionContainer.module.scss"],"names":[],"mappings":"AAAA,4BACI,UAAA,CACA,yBAAA,CAGJ,0CACI,4BACI,kBAAA,CACA,kBAAA,CACA,gBAAA,CACA,oBAAA,CAAA,CAIR,4BACI,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CAGJ,4BACI,cAAA,CACA,kBAAA,CAGJ,4BACI,iBAAA,CACA,oBAAA,CACA,cAAA,CAGJ,6BACI,WAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CAGJ,6BACI,2BAAA,CACA,wBAAA","sourcesContent":[".main-container {\n    width: 100%;\n    padding: 0.5em 1em 0.5em 1em;\n}\n\n@media only screen and (max-width: 480px) {\n    .main-container {\n        border-radius: 20px;\n        border-style: solid;\n        border-width: 1px;\n        border-color: #033572;\n    }\n}\n\n.header-container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    min-height: 48px;\n}\n\n.content-container {\n    margin-top: 1em;\n    padding-bottom: 1em;\n}\n\n.icon-container {\n    margin-right: 0.5em;\n    display: inline-block;\n    font-size: 35px;\n}\n\n.expand-icon-container {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n}\n\n.expand-icon {\n    justify-self: end !important;\n    display: block !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-container": "Bjr-rdxlkM9vKQ7robkLgw==",
	"header-container": "YV3cD9j9a3dW0S19l2ZrCw==",
	"content-container": "EMEiEBi3mxe669klojm0Cg==",
	"icon-container": "JctYIp4CZZVdEm74CnyMqA==",
	"expand-icon-container": "QLOu5DLXJyMn9kpux+aHEQ==",
	"expand-icon": "+ELX6QhCf3KVjSTUEofDPA=="
};
export default ___CSS_LOADER_EXPORT___;
