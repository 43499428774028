import React, { Component } from "react";

import styles from "./SectionContainer.module.scss";
import { Typography } from "@material-ui/core";
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import IconButton from "@material-ui/core/IconButton";
import { Collapse } from "@material-ui/core";

import {
    Link
} from "react-router-dom";

class SectionContainer extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        };
    
    }

    render() {

        let IconContainer;
        let Icon;
        let ExpandIcon;

        Icon = this.props.icon;

        if(this.props.expandable === true) {

            if(this.props.expanded === true) {

                ExpandIcon = <IconButton color="inherit" aria-label="Rozwinięto">
                    <ArrowDropDownOutlinedIcon
                        className={styles["expand-icon"]}
                    />
                </IconButton>;
            
            } else {

                ExpandIcon = <IconButton color="inherit" aria-label="Zwinięto">
                    <ArrowRightOutlinedIcon
                        color="inherit"
                        className={styles["expand-icon"]}
                    />
                </IconButton>;
            
            }
        
        }

        if(Icon) {

            IconContainer = <div className={styles["icon-container"]}>{Icon}</div>;
        
        }

        let header = (
            <div className={styles["header-container"]}>
                {IconContainer}
                <Typography variant="h5">{this.props.title}</Typography>
                <div className={styles["expand-icon-container"]}>
                    {ExpandIcon}
                </div>
            </div>
        );


        if(this.props.routeConfig != null) {

            header = (
                <Link to={this.props.routeConfig.to} style={{textDecoration: "none", color: "inherit"}}>
                    {header}
                </Link>
            );
        
        }

        return (

            <div className={styles["main-container"] + " " + this.props.className}>
                
                {header}

                <Collapse in={this.props.expanded}>
                    <div className={styles["content-container"]}>
                        {this.props.children}
                    </div>
                </Collapse>
            </div> 
        );
    
    }

}

export default SectionContainer;